import { render, staticRenderFns } from "./CheckPWB_JapanPrizes.vue?vue&type=template&id=1b513a28"
import script from "./CheckPWB_JapanPrizes.vue?vue&type=script&lang=js"
export * from "./CheckPWB_JapanPrizes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports