import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('h3',[_vm._v("จัดการรายการสั่งซื้อ")])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{staticClass:"mr-4",attrs:{"cols":"4"}},[_c(VTextField,{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VSpacer)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headersTransaction,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:`item.fullname`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.user.firstName + " " + item.user.lastName))])]}},{key:`item.deliveryStatus`,fn:function({ item }){return [(item.deliveryStatus == `WAITING`)?_c(VChip,[_vm._v("รอจัดส่ง")]):(item.deliveryStatus == `SENT`)?_c(VChip,{attrs:{"color":"green","dark":""}},[_vm._v("ส่งแล้ว")]):_vm._e()]}},{key:`item.isDelivery`,fn:function({ item }){return [(item.isDelivery == true)?_c('span',[_vm._v("จัดส่งตามที่อยู่")]):(item.isDelivery == false)?_c('span',{attrs:{"color":"green","dark":""}},[_vm._v("เก็บในตู้เซพ")]):_vm._e()]}},{key:`item.action`,fn:function({ item }){return [_c(VRow,{attrs:{"justify":"center"}},[_c(VIcon,{on:{"click":function($event){return _vm.viewTransaction(item)}}},[_vm._v("mdi-eye")]),_c(VIcon,{on:{"click":function($event){return _vm.DeleteTransaction(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }