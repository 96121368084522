<template>
  <v-card class="pa-8">
    <h3>ข้อมูลผู้ใช้งาน</h3>
    <!-- <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">รหัสตัวแทน</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="agentNo"
          label="รหัสตัวแทน"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p class="pt-2">อนุมัติตัวแทน</p></v-col>
      <v-col cols="4">
        <v-switch v-model="activeFlag" class="mt-1"></v-switch>
      </v-col>
    </v-row> -->
    <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">ชื่อจริง</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="firstName"
          label="ชื่อจริง"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p class="pt-2">นามสกุล</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="lastName"
          label="นามสกุล"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">อีเมล</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="email"
          label="อีเมล"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p class="pt-2">หมายเลขโทรศัพท์</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="tel"
          label="หมายเลขโทรศัพท์"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mb-6"></v-divider>
    <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">ธนาคาร</p></v-col>
      <!-- <v-col cols="4">
        <v-autocomplete
          v-model="bankName"
          item-text="name"
          :items="[
            { name: 'ธนาคารกรุงเทพฯ', value: '1' },
            { name: 'ธนาคารไทยพาณิชย์ฯ', value: '2' },
            { name: 'ธนาคารกสิกรไทย', value: '3' },
            { name: 'ธนาคารกรุงศรีอยุธยา', value: '4' },
            { name: 'ธนาคารธนชาต', value: '5' },
            { name: 'ธนาคารกรุงไทย', value: '6' },
            { name: 'ธนาคารทิสโก้', value: '7' },
            { name: 'ธนาคารออมสิน', value: '8' },
            { name: 'ธนาคารอาคารสงเคราะห์', value: '9' },
            { name: 'ธนาคารอิสลามแห่งประเทศไทย', value: '10' },
            { name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร', value: '11' },
            { name: 'ธนาคารทหารไทยธนชาต', value: '12' },
          ]"
          label="ธนาคาร"
          outlined
          dense
        ></v-autocomplete>
      </v-col> -->
      <v-col cols="4">
        <v-autocomplete
          v-model="bankName"
          item-text="name"
          :items="[
            'ธนาคารกรุงเทพฯ',
            'ธนาคารไทยพาณิชย์ฯ',
            'ธนาคารกสิกรไทย',
            'ธนาคารกรุงศรีอยุธยา',
            'ธนาคารธนชาต',
            'ธนาคารกรุงไทย',
            'ธนาคารทิสโก้',
            'ธนาคารออมสิน',
            'ธนาคารอาคารสงเคราะห์',
            'ธนาคารอิสลามแห่งประเทศไทย',
            'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
            'ธนาคารทหารไทยธนชาต',
          ]"
          label="ธนาคาร"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p class="pt-2">หมายเลขบัญชีธนาคาร</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="bankNo"
          label="หมายเลขบัญชีธนาคาร"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="2"><p class="pt-2">ชื่อบัญชี</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="accountName"
          label="ชื่อบัญชี"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="2"><p class="pt-2">VIP</p></v-col>
      <v-col cols="4">
        <v-select
          v-model="member"
          :items="memberItems"
          label="VIP"
          outlined
          dense
        ></v-select>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn v-if="isHelper == false" @click="changeIsHelper()"
          >เปลี่ยนให้เป็นผู้ช่วยขาย</v-btn
        >
        <div v-if="isHelper == true">
          <span style="color: green">เป็นผู้ช่วยขายแล้ว</span>
          <br />
          <br />
          <span>รหัสผู้ช่วยขาย: {{ telCode }}</span>
          <br />
          <br />
          <span>ลิ้งค์</span>
          <br />
          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-text-field
                v-model="linkAffiliation"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="3"></v-col>
          </v-row>
          <v-btn small color="#2e2e2e" @click="copyURLAff()">
            <v-icon size="16px" style="color: #fff">mdi-copy-outline</v-icon>
            <span style="color: #fff">คัดลอก</span>
          </v-btn>
          <!-- <v-btn @click="cancelIsHelper(false)">ยกเลิกการเป็นผู้ช่วยขาย</v-btn> -->
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"><p class="pt-2">รูปบัตรประจำตัวประชาชน</p></v-col>
      <v-img :src="idCardImgUrl"></v-img>
    </v-row>
    <v-row>
      <v-col cols="4"><p class="pt-2">รูปถ่ายคู่บัตรประชาชน</p></v-col>
      <v-img :src="idCardWithPersonImgUrl"></v-img>
    </v-row>

    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit()" color="primary">แก้ไข</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      tel: "",
      activeFlag: false,
      agentNo: "",
      bankName: "",
      bankNo: "",
      accountName: "",
      idCardImgUrl: "",
      idCardWithPersonImgUrl: "",
      member: "",
      telCode: "",
      isHelper: "",
      memberItems: ["ปกติ", "VIP", "VIP+"],
      // activeFlag: false
      // agentNo: "20211105000002"
      // bankName: "Kbank"
      // bankNo: "12354"
      // count: 1
      // createdAt: "2021-11-05T11:52:43.000Z"
      // email: "ittiwut.wong2@gmail.com"
      // firstName: "Ittiwut"
      // id: 2
      // idCard: "1343423232"
      // idCardImgUrl: "url2"
      // idCardWithPersonImgUrl: "url1"
      // lastName: "fdf"
      // member: null
      // tel: "0990569592"
      // updatedAt: "2021-11-21T11:22:12.000Z"

      linkAffiliation: "",
    };
  },
  created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log("userdata", userdata);
    this.id = userdata.id;
    this.firstName = userdata.firstName;
    this.lastName = userdata.lastName;
    this.email = userdata.email;
    this.tel = userdata.telNumber;
    this.accountName = userdata.accountName;
    this.activeFlag = userdata.activeFlag;
    this.agentNo = userdata.agentNo;
    this.bankName = userdata.bankName;
    this.bankNo = userdata.bankNo;
    this.member = userdata.member;
    this.isHelper = userdata.isHelper;
    this.idCardImgUrl = userdata.idCardImgUrl;
    this.idCardWithPersonImgUrl = userdata.idCardWithPersonImgUrl;
    this.telCode = userdata.telCode;

    this.linkAffiliation =
      "https://rachachoklottery.com/?refNumber=" + this.telCode;
  },
  methods: {
    copyURLAff() {
      navigator.clipboard.writeText(this.linkAffiliation);
      this.$swal.fire({
        icon: "success",
        title: "คัดลอกสำเร็จ",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    async changeIsHelper() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      this.telCode = result + this.id;
      this.isHelper = true;
    },
    async cancelIsHelper() {
      this.isHelper = false;
      this.telCode = null;
    },
    async submit() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const data = {
        id: this.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        tel: this.tel,
        activeFlag: this.activeFlag,
        agentNo: this.agentNo,
        bankName: this.bankName,
        bankNo: this.bankNo,
        accountName: this.accountName,
        telNumber: this.tel,
        isHelper: this.isHelper,
        telCode: this.isHelper ? this.telCode : null,
        member: this.member,
        idCardImgUrl: this.idCardImgUrl,
        idCardWithPersonImgUrl: this.idCardWithPersonImgUrl,
      };
      console.log("data", data);
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/` + this.id,
        data,
        auth
      );
      console.log("createUser", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขข้อมูลสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        // this.$router.push("ManageUser");
        this.$router.go(-1);
      } else {
        this.$swal.fire({
          icon: "error",
          text: `แก้ไขข้อมูลไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>
