import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('h3',[_vm._v("ข้อมูลผู้ใช้งาน")])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{staticClass:"mr-4",attrs:{"cols":"4"}},[_c(VTextField,{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VSpacer)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headersCategory,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [_c(VRow,{attrs:{"justify":"center"}},[_c(VIcon,{on:{"click":function($event){return _vm.viewUser(item)}}},[_vm._v("mdi-pencil")]),_c(VIcon,{on:{"click":function($event){return _vm.DeleteUser(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:`item.bankAccount`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.bankNo)+" "+_vm._s(item.bankName)+" ")]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }