import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c(VApp,{attrs:{"id":"inspire"}},[_c(VNavigationDrawer,{attrs:{"app":"","width":"300px"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{staticClass:"mt-4",attrs:{"height":"250"}},[_c('a-row',{attrs:{"type":"flex","justify":"center","align":"middle"}},[_c('a-col',{staticClass:"centerdiv",attrs:{"span":24}},[_c(VImg,{attrs:{"src":require("../assets/BKLTH.png"),"max-width":"250"}})],1)],1)],1),_c(VDivider),_c('a-menu',{attrs:{"mode":"vertical"}},_vm._l((_vm.Navigator),function(item){return _c('a-menu-item',{key:item.key,on:{"click":function($event){return _vm.ChangePage(item)}}},[_c('a-icon',{attrs:{"type":item.icon}}),_c('span',{staticClass:"pl-5"},[_vm._v(_vm._s(item.text))])],1)}),1)],1),_c(VAppBar,{attrs:{"app":"","color":"#b8deff"}},[_c(VAppBarNavIcon,{staticStyle:{"color":"white"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('h3',[_vm._v(_vm._s(_vm.Header))]),_c(VSpacer),_c(VBtn,{attrs:{"color":"white","text":""},on:{"click":_vm.Logout}},[_vm._v("ออกจากระบบ"),_c('a-icon',{staticClass:"ml-2",attrs:{"type":"import"}})],1)],1),_c(VMain,{staticClass:"ma-4"},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }