import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"pa-8"},[_c('h3',[_vm._v("ข้อมูลผู้ใช้งาน")]),_c(VRow,{staticClass:"text-end"},[_c(VCol,{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_c('b',[_vm._v("ชื่อจริง:")])])]),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"placeholder":"ชื่อจริง","solo":"","dense":"","type":"text"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_c('b',[_vm._v("นามสกุล:")])])]),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"placeholder":"นามสกุล","solo":"","dense":"","type":"text"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)],1),_c(VRow,{staticClass:"text-end"},[_c(VCol,{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_c('b',[_vm._v("อีเมล:")])])]),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"placeholder":"อีเมล","solo":"","dense":"","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_c('b',[_vm._v("หมายเลขโทรศัพท์:")])])]),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"placeholder":"เบอร์โทรศัพท์","solo":"","dense":"","type":"text"},model:{value:(_vm.telNumber),callback:function ($$v) {_vm.telNumber=$$v},expression:"telNumber"}})],1)],1),_c(VDivider,{staticClass:"mb-6"}),_c(VRow,[_c(VBtn,{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("กลับ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("ตกลง")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }