import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"pa-8"},[_c('h3',[_vm._v("ข้อมูลผู้ใช้งาน")]),_c(VRow,{staticClass:"text-end"},[_c(VCol,{attrs:{"cols":"6","md":"2"}},[_c('p',{staticClass:"pt-2"},[_vm._v("ชื่อร้าน")])]),_c(VCol,{attrs:{"cols":"6","md":"4"}},[_c(VTextField,{attrs:{"label":"ชื่อร้าน","outlined":"","dense":"","disabled":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1)],1),_c(VRow,{staticClass:"text-end"},[_c(VCol,{attrs:{"cols":"6","md":"2"}},[_c('p',{staticClass:"pt-2"},[_vm._v("จำนวนโควต้า")])]),_c(VCol,{attrs:{"cols":"6","md":"4"}},[_c(VTextField,{attrs:{"label":"จำนวนโควต้า","outlined":"","dense":""},model:{value:(_vm.clickCount),callback:function ($$v) {_vm.clickCount=$$v},expression:"clickCount"}})],1),_c(VSpacer)],1),_c(VRow,{staticClass:"mt-6"},[_c(VBtn,{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("กลับ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("แก้ไข")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }