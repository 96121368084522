import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('h1',{staticStyle:{"font-weight":"800","color":"#ff0000"}},[_vm._v("จัดการแบนเนอร์")])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{staticClass:"mr-4",attrs:{"cols":"4"}},[_c(VTextField,{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VSpacer),_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.goToCreate()}}},[_vm._v("เพิ่มแบนเนอร์")])],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headersbanners,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:`item.imgUrl`,fn:function({ item }){return [_c(VRow,{attrs:{"align":"center"}},[_c(VImg,{staticClass:"ma-6",attrs:{"src":item.imgUrl,"width":"20px"}})],1)]}},{key:`item.activeFlag`,fn:function({ item }){return [(item.activeFlag == true)?_c(VChip,{staticClass:"orange lighten-2"},[_vm._v("ใช้งาน")]):_vm._e(),(item.activeFlag == false)?_c(VChip,{staticClass:"grey lighten-9 white--text"},[_vm._v("ไม่ใช้งาน")]):_vm._e()]}},{key:`item.updatedAt`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.convertDate(item.updatedAt)))])]}},{key:`item.action`,fn:function({ item }){return [_c(VRow,{attrs:{"justify":"center"}},[_c(VIcon,{on:{"click":function($event){return _vm.Deletebanners(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }